<template>
  <b-card :title="`${title} Summary`">

    <!-- Format: General -->
    <GeneralSummaryEditor
      v-if="['General', 'Keyworker'].includes(scribeSummary.summary_format)"
      :id="scribeSummary.id"
      :summary="scribeSummary.summary"
      :editable="true"
      @update="updateSummary"
    />

    <!-- Format: JSON -->
    <JsonSummary
      v-if="scribeSummary.summary_format === 'JSON'"
      :summary="scribeSummary.summary"
    />

    <!-- Format: QuestionAndAnswer -->
    <QAndASummary
      v-if="scribeSummary.summary_format === 'QuestionAndAnswer'"
      :summary="scribeSummary.summary"
    />
  </b-card>
</template>

<script>
import GeneralSummaryEditor from "@/views/purpleScribe/sections/GeneralSummaryEditor.vue";
import PurpleScribeService from "@/services/PurpleScribeService";
import helperService from "@/services/HelperService";
import JsonSummary from "@/views/purpleScribe/sections/JsonSummary.vue";
import QAndASummary from "@/views/purpleScribe/sections/QAndASummary.vue";

export default {
  components: {QAndASummary, JsonSummary, GeneralSummaryEditor},
  props: {
    summary: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      scribeSummary: this.$props.summary
    }
  },
  computed: {
    title() {
      const title = this.$options.filters.capEachWord(this.$options.filters.splitCamelCase(this.summary.summary_type))
      if(title === 'Assurance Alliance Action Plan') {
        return 'CSAP'
      }
      return title
    }
  },
  methods: {
    async updateSummary(updatedSummary) {
      try {
        const {data} = await PurpleScribeService.updateSummary(this.summary.id, {
          summary: updatedSummary,
        })
        this.scribeSummary = data
        this.$toast.success('Summary was successfully updated.', {
          toastClassName: ['toast-std', 'success-toast'],
        })
        this.$emit('summary:updated', this.scribeSummary)
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while updating the summary. Please refresh and try again.');
      }
    }
  }
}
</script>
